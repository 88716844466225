/* styles.css */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

.fw-footer-root-small {
  max-width: 1440px;
  margin: 0 auto;
}

.fw-footer-root {
  max-width: 1920px;
  margin: 0 auto;
  border-right: 0.5px solid #fff4eb;
  border-left: 0.5px solid #fff4eb;
}

.fw-footer-widgets {
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;
}

.fw-inner,
.fw-col-inner {
  position: relative;
  z-index: 10;
}

.fw-footer-menu {
  text-align: center;

  white-space: nowrap;
}

.fw-footer-menu * {
  white-space: wrap;
}

.fw-footer-menu ul > li {
  flex: 1;
}

@media (max-width: 1219.95px) {
  .fw-footer-menu ul {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    margin: 32px 0;
  }
  .fw-footer-menu ul > li {
    margin-top: 16px;
  }
}
@media (min-width: 600px) {
  .fw-footer-menu ul {
    display: flex;
    flex-direction: row;
    display: flex;

    justify-content: center;
    align-items: center;
    margin: 32px 0;
  }
}
@media (min-width: 1220px) {
  .fw-footer-menu ul {
  }
}

.widget_text .textwidget ul,
.widget_text .textwidget ol {
  padding-left: 20px;
  margin-bottom: 1em;
}
.widget_text .textwidget ul {
  list-style: inherit;
}
.fw-footer-menu ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  font-size: 0;
  line-height: normal;
}
.widget ul {
  list-style: none;
  overflow: hidden;
  padding-left: 0;
  margin: 0;
}

.fw-footer-widgets .widget,
.fw-footer-widgets .widget li {
  color: #898d8e;
}
.fw-footer-menu ul li {
  font-family: "Lato";
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  position: relative;
  display: inline-block;
}
.fw-footer-menu ul li:first-child a {
}

.fw-footer-widgets .widget a,
.fw-footer-widgets .widget .comment-author-link a {
  color: #000000;
}
.widget {
  margin-bottom: 80px;
  font-size: 15px;
  line-height: 26px;
}
.widget_text .textwidget a {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 1px;
}
.fw-footer-menu ul li a:after {
  content: "";
  left: 50%;
  position: absolute;
  width: 0;
  background: #ffd02d;
  top: 22px;
  height: 1px;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.fw-row {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 992px) {
  .fw-col-md-12 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .fw-col-md-1,
  .fw-col-md-2,
  .fw-col-md-3,
  .fw-col-md-4,
  .fw-col-md-5,
  .fw-col-md-6,
  .fw-col-md-7,
  .fw-col-md-8,
  .fw-col-md-9,
  .fw-col-md-10,
  .fw-col-md-11,
  .fw-col-md-12,
  .fw-col-lg-1-5,
  .fw-col-md-1-5,
  .fw-col-sm-1-5 {
    float: left;
  }
}
.fw-col-xs-1,
.fw-col-sm-1,
.fw-col-md-1,
.fw-col-lg-1,
.fw-col-xs-2,
.fw-col-sm-2,
.fw-col-md-2,
.fw-col-lg-2,
.fw-col-xs-3,
.fw-col-sm-3,
.fw-col-md-3,
.fw-col-lg-3,
.fw-col-xs-4,
.fw-col-sm-4,
.fw-col-md-4,
.fw-col-lg-4,
.fw-col-xs-5,
.fw-col-sm-5,
.fw-col-md-5,
.fw-col-lg-5,
.fw-col-xs-6,
.fw-col-sm-6,
.fw-col-md-6,
.fw-col-lg-6,
.fw-col-xs-7,
.fw-col-sm-7,
.fw-col-md-7,
.fw-col-lg-7,
.fw-col-xs-8,
.fw-col-sm-8,
.fw-col-md-8,
.fw-col-lg-8,
.fw-col-xs-9,
.fw-col-sm-9,
.fw-col-md-9,
.fw-col-lg-9,
.fw-col-xs-10,
.fw-col-sm-10,
.fw-col-md-10,
.fw-col-lg-10,
.fw-col-xs-11,
.fw-col-sm-11,
.fw-col-md-11,
.fw-col-lg-11,
.fw-col-xs-12,
.fw-col-sm-12,
.fw-col-md-12,
.fw-col-lg-12,
.fw-col-lg-1-5,
.fw-col-md-1-5,
.fw-col-sm-1-5 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.fw-footer-widgets .widget,
.fw-footer-widgets .widget li {
  color: #898d8e;
  margin-right: 25px;
}
.widget:last-child {
  margin-bottom: 0;
}
.widget {
  margin-bottom: 80px;
  font-size: 15px;
  line-height: 26px;
}
.fw-footer-widgets .widget_text .textwidget {
  font-size: 14.85px;
}
.fw-footer-middle {
  position: relative;
}
.fw-footer-middle {
  background-color: #ffffff;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;
}

.widget a,
.fw-footer-widgets .widget .comment-author-link a {
  color: #000000;
}
.widget_text .textwidget a {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 1px;
}
.fw-footer-widgets .widget a,
.fw-footer-widgets .widget .comment-author-link a {
  color: #898d8e;
}
