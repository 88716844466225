.fw-header {
  background: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.fw-header-small {
  max-width: 1440px;
}

.fw-header-non-transparent {
  background-color: #fdf9f6 !important;
}

.fw-absolute-header .fw-header .fw-header-main {
  background: transparent;
  background: rgba(0,0,0,0);
}
.fw-header .fw-header-main {
  width: 100%;
  text-align: right;
  position: relative;
  z-index: 100;
  background: transparent;
  border-left: 1px solid #fff4eb;
  border-right: 1px solid #fff4eb;
}

.fw-header-main .fw-container {
  padding-top: 35px;
  padding-bottom: 35px;
}
.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fw-container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: table;
}
.fw-container:before, .fw-container:after {
  content: " ";
  display: table;
}
.fw-container, .fw-container-fluid {
  z-index: 10;
}
.fw-container, .fw-container-fluid {
  z-index: 10;
}
@media (min-width: 768px)  {
  .fw-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .fw-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .fw-container {
    width: 1170px;
  }
}

@media (max-width: 1199px) {
  .fw-site-navigation, .header-2 .fw-nav-wrap.fw-nav-left, .header-2 .fw-nav-wrap.fw-nav-right, .header-3 .fw-site-navigation, .header-4 .fw-site-navigation {
      display: none !important;
  }
}

.fw-site-logo img {
  width: 244.5px;
}
.fw-site-logo, .fw-sticky-logo-retina .fw-header.fw-sticky-menu .fw-site-logo, .fw-sticky-logo-no-retina .fw-header.fw-sticky-menu .fw-site-logo {
  line-height: normal;
}
.fw-site-logo {
  display: block;
  text-align: center;
  max-width: 100%;
}

.fw-header .fw-wrap-logo img {
  filter: none;
}
.fw-header .fw-wrap-logo img {
  filter: b
}

.mmenu-link {
  display: none;
  line-height: 0;
  white-space: nowrap;
}

.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
a {
  color: #ffd02d;
}
a {
  color: #428bca;
  text-decoration: none;
}
a {
  background: transparent;
}

.fw-wrap-logo {
  float: left;
}
.fw-wrap-logo {
  font-family: 'Lato';
  z-index: 100;
}
/* .fw-wrap-logo img {
  max-width: 220px;
} */

@media (max-width: 1199px) {
  .fw-wrap-logo {
      width: auto;
      float: left;
  }
  .fw-wrap-logo img {
    padding: 20px;
    max-width: 640px !important;
  }
}
@media (max-width: 1199px) {
  .fw-wrap-logo {
      float: inherit;
      display: inline-block;
      width: 100%;
  }
}

.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fw-site-logo {
  width: 20%;
}
.fw-site-logo, .fw-sticky-logo-retina .fw-header.fw-sticky-menu .fw-site-logo, .fw-sticky-logo-no-retina .fw-header.fw-sticky-menu .fw-site-logo {
  line-height: normal;
}
.fw-wrap-logo .fw-site-logo {
  display: block;
  text-align: center;
  max-width: 100%;
}
.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fw-top-logo-left .fw-nav-wrap {
  float: right;
}
.fw-nav-wrap {
  max-width: 100%;
  margin: 15px 0;
}
.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fw-site-navigation {
  float: right;
}

.fw-site-navigation > ul {
  font-weight: 400;
}
.fw-site-navigation > ul {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}
.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.primary-navigation > ul > li {
  border-bottom: none;
}
.fw-site-navigation > ul > li {
  float: left;
  position: relative;
  margin-right: 20px;
}
.fw-site-navigation > ul li {
  position: relative;
  list-style: none;
}
.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fw-header .fw-site-navigation > ul > li > a {
  color: #000000 !important;
}
.fw-site-navigation > ul > li.current-menu-item > a {
  color: #ffd02d;
}
.header-1 .primary-navigation > ul > li > a {
  margin-right: 0;
  margin-left: 40px;
}
.fw-site-navigation > ul > li > a {
  display: inline-block;
  float: left;
  position: relative;
  color: #ebebeb;
  line-height: 30px;
  letter-spacing: 0px;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fw-site-navigation > ul > li.current-menu-item a:after, .fw-site-navigation > ul > li.current-menu-ancestor a:after {
  width: 100%;
  margin-left: -50%;
}
.fw-site-navigation > ul > li > a:after {
  content: "";
  bottom: 0;
  height: 1px;
  left: 50%;
  position: absolute;
  width: 0;
  background: #ffd02d;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.fw-container, .fw-container *, .fw-container *:before, .fw-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Mobile navi */
.mobile-nav {
    float: right;
    list-style: none;
    position: relative;
}
.mobile-nav-icon {
    cursor: pointer;
    display: none;
    margin-top: 19px;
    color: #717271;
    border: none;
}