.App {
  text-align: center;
  /* max-width: 1920px; */
  width: 100%;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FAFAFA;
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiPaper-root {
  color: #535353;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }


/*
    Orange color: #e79913
*/

/* ----------------------------------------- */
/* BASIC SETUP */
/* ----------------------------------------- */

html {
  scroll-behavior: smooth !important;
  font-size: 120%;
}

*, *::before, *::after {
  border-width: 0;
  border-style: solid;
  border-color: #fff4eb;
}

.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

*:focus {
  outline: 0 !important;
}
figure {
  margin: 0 0 1rem;
}
/* ----------------------------------------- */
/* REUSABLE COMPONENTS */
/* ----------------------------------------- */

.hide {
  display: none;
}

.show {
  display: block;
}

header .row {
  max-width: 1140px;
  margin: 0 auto;
  padding: 10px 0;
}

section {
  padding: 0;
}

.paragraph {
  text-align: justify !important;
  word-break: keep-all;
  font-size: 110%;
  font-weight: 500;
}

/* .lead {
  padding-left: 20px;
} */

.quote {
  font-size: 150%;
  /* margin: 35px 20px; */
  max-width: 680px;
  margin: 0 auto;
}

.author {
  font-size: 68% !important;
  margin-top: 17px;
}

.author-large {
  font-size: 100% !important;
  margin-top: 17px;
}

.font-150 {
  font-size: 150%;
}

.box {
  padding: 1%;
}

.text-orange {
  color: #e79913;
}

.text-blue {
  color: #3355c1;
}

.text-green {
  color: #53c051;
}

.text-white {
  color: #ffffff;
}

.text-dark {
  color: #000000 !important;
}

.heavy {
  font-family: 'futura-pt';
  font-weight: 700 !important;
  line-height: 18px !important;
}

.heavy-italic {
  font-family: 'futura-pt';
  font-weight: 700;
  font-style: italic;
}

.demi {
  font-family: 'futura-pt';
  font-weight: 600 !important;
}

.demi-italic {
  font-family: 'futura-pt';
  font-weight: 600;
  font-style: italic;
}

.demi-oblique {
  font-family: 'futura-pt';
  font-weight: 600;
  font-style: oblique;
}

.medium {
  font-family: 'futura-pt';
  font-weight: 500 !important;
}

.medium-italic {
  font-family: 'futura-pt';
  font-weight: 500;
  font-style: italic;
}

.medium-oblique {
  font-family: 'futura-pt';
  font-weight: 500;
  font-style: oblique;
}

/* ---------- HEADINGS ------------*/

.text-120 {
  font-size: 120%;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
  line-height: normal !important;
}

h1{font-size:2.5rem}.h1,h2{font-size:2rem}.h3,h3{font-size:1.75rem}.h4,h4{font-size:1.5rem}.h5,h5{font-size:1.25rem}.h6,h6{font-size:1rem}
.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.long-copy {
  line-height: 145%;
  width: 70%;
  margin-left: 15%;
}

.long-copy span {
  font-style: italic;
}

.longer-copy {
  line-height: 145%;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 20px;
}

.longer-copy span {
  font-style: italic;
}

.box p {
  font-size: 90%;
  line-height: 145%;
}

/* ---------- ICONS ------------*/

.icon-big {
  font-size: 350%;
  display: block;
  color: #e79913;
  margin-bottom: 10px;
}

.icon-small {
  display: inline-block;
  width: 30px;
  text-align: center;
  color: #e79913;
  font-size: 120%;
  margin-right: 10px;

  /* Secrets to align text and icons */
  line-height: 120%;
  vertical-align: middle;
  margin-top: -5px;
}

.icon-xs {
  display: inline-block;
  width: 25px;
  text-align: center;
  color: #e79913;
  font-size: 80%;
  margin-right: 10px;

  /* Secrets to align text and icons */
  line-height: 120%;
  vertical-align: middle;
  margin-top: -5px;
}

/* ---------- LINKS ------------*/

/* a:link,
a:visited {
  color: #e79913;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid #e79913;
  -webkit-transition: border-bottom 0.2s, color 0.2s;
  transition: border-bottom 0.2s, color 0.2s;
}

a:hover,
a:active {
  color: #555;
  border-bottom: 1px solid transparent;
} */

.icon-video:link,
.icon-video:active {
  text-decoration: none;
  border: none;
}

.ion-video-link {
  -webkit-transition: color 0.2s;
  transition: color 0.2s;

  display: inline-block;
  width: 30px;
  text-align: center;
  color: #cc181e;
  font-size: 130%;
  margin-right: 10px;

  /* Secrets to align text and icons */
  line-height: 120%;
  vertical-align: middle;
  margin-top: -5px;
}

.ion-video-link:hover {
  color: #b21d22;
}

/* ---------- BUTTONS ------------*/

section#home-intro #home-intro-ctnr a.button {
  background-color: #00000080 !important;
}

.donately-btn {
  width: 100% !important;
}

.btn:link,
.btn:visited,
input[type='submit'] {
  display: inline-block;
  padding: 10px 30px;
  font-weight: 400;
  text-decoration: none;
  border-radius: 5px;
  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
  transition: background-color 0.2s, border 0.2s, color 0.2s;
  cursor: pointer;
}

.btn-full:link,
.btn-full:visited,
input[type='submit'] {
  background-color: #e79913;
  border: 1px solid #e79913;
  color: #fff;
  margin-right: 15px;
}

.btn-ghost:link,
.btn-ghost:visited {
  border: 1px solid #e79913;
  color: #e79913;
}

.btn:hover,
.btn:active,
input[type='submit']:hover,
input[type='submit']:active {
  background-color: #f58618;
}

.btn-full:hover,
.btn-full:active,
input[type='submit'] {
  border: 1px solid #e79913;
}

.btn-ghost:hover,
.btn-ghost:active {
  border: 1px solid #e79913;
  color: #fff;
}

/* ----------------------------------------- */
/* HEADER */
/* ----------------------------------------- */

.hero-water {
  color: #ffffff;
  position: relative;
  top: 25%;
  left: 0;
  width: 100%;
  margin-top: 0;
}

.hero-water img {
  width: 100%;
}

.hero-water .donate-button {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.hero-water .centered {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.hero-water h1 {
  font-family: 'futura-pt';
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  color: #fff;
  font-size: 285%;
  letter-spacing: -1px;
  text-transform: none;
  line-height: 40px;
  white-space: nowrap;
}

.hero-water h2 {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 250%;
}

.hero-water h4 {
  font-size: 184%;
  line-height: 40px;
}

.hero-water img {
  height: 100%;
}

.hero-text-box {
  position: absolute;
  width: 1140px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo {
  height: 60px;
  width: auto;
  /* float: left; */
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-black {
  display: none;
  height: 50px;
  width: auto;
  float: left;
  margin: 5px 0;
}

.bg-inverse {
  background-color: #252f3f !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-dark {
  background-color: #141414 !important;
}

/*   Main Navi */
.main-nav {
  float: right;
  list-style: none;
  margin-top: 25px;
}

.main-nav li {
  display: inline-block;
  margin-left: 40px;
  overflow: hidden;
}

.main-nav li a:link,
.main-nav li a:visited {
  /* padding: 8px 0; */
  color: #fff;
  text-decoration: none;
  /* text-transform: uppercase; */
  word-spacing: 5px;
  font-size: 80%;
  border-bottom: 2px solid transparent;
  -webkit-transform: border-bottom 0.2s;
  transform: border-bottom 0.2s;
}

.main-nav li a:hover,
.main-nav li a:active {
  border-bottom: 2px solid #e79815;
}

/* Sticky Navi */
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 2px 2px #efefef;
  z-index: 9999;
}

.sticky .main-nav {
  margin-top: 18px;
  margin-bottom: 18px;
}

.sticky .main-nav li a:link,
.sticky .main-nav li a:visited {
  padding: 18px 0;
  color: #555;
}

.sticky .logo {
  display: none;
}
.sticky .logo-black {
  display: block;
}

/* Mobile Navi */
.mobile-nav-icon {
  float: right;
  margin-top: 30px;
  cursor: pointer;
  display: none;
}

.mobile-nav-icon i {
  font-size: 150%;
  margin-left: 20px;
  color: #fff;
}

.close-menu {
  display: none;
}

.close-menu.menuopen {
  display: block;
}

.hideMenu {
  display: none;
}

/* ----------------------------------------- */
/* Power for change */
/* ----------------------------------------- */

.boys-power {
  padding: 1rem !important;
  border: none;
}

#boys-power {
  position: relative;
  /* margin-top: 10px; */
  height: 100%;
}

#boys-power .centered {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -35%);
  white-space: nowrap;
}

#boys-power .img-logo {
  height: 14%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -35%);
  white-space: nowrap;
}

/* ----------------------------------------- */
/* VIDEOS */
/* ----------------------------------------- */



.boy-water {
  padding: 1rem !important;
  border: none;
  opacity: 1;
}

.section-videos {
  background-color: #f3f0f0;
}

.video {
  position: relative;
}

.video .play-button-off {
  opacity: 0;
}

#boy-water {
  position: relative;
  /* margin-top: 10px; */
  height: 100%;
}

#boy-water .centered {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -35%);
  white-space: nowrap;
}

#boy-water .img-logo {
  height: 14%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -35%);
  white-space: nowrap;
}

/* ----------------------------------------- */
/* FEATURES */
/* ----------------------------------------- */

.section-features .long-copy {
  margin-bottom: 30px;
}

/* ----------------------------------------- */
/* PHOTOS SHOWCASE */
/* ----------------------------------------- */

.image-settings {
  /* padding: 1rem !important; */
  border: none;
}

.card-block {
  padding: 1.25rem 0 !important;
  border: none;
}

/* ----------------------------------------- */
/* FOOTER */
/* ----------------------------------------- */

/* footer {
  background-color: #333333;
  padding: 50px;
  font-size: 80%;
} */

.footer-nav {
  list-style: none;
  float: left;
}

.footer-social {
  list-style: none;
  float: right;
}

.footer-nav li,
.footer-social li {
  display: inline-block;
  margin-right: 20px;
}

.footer-nav li:last-child,
.footer-social li:last-child {
  margin: 0;
}

.footer-nav li a:link,
.footer-nav li a:visited,
.footer-social li a:link,
.footer-social li a:visited {
  text-decoration: none;
  border: 0;
  color: #888888;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.footer-nav li a:hover,
.footer-nav li a:active {
  color: #ddd;
}

.footer-social li a:link,
.footer-social li a:visited {
  font-size: 160%;
}

.ion-social-facebook,
.ion-social-twitter,
.ion-social-googleplus,
.ion-social-instagram {
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.ion-social-facebook:hover {
  color: #3b5998;
}

.ion-social-twitter:hover {
  color: #55acee;
}

.ion-social-googleplus:hover {
  color: #dd4b39;
}

.ion-social-instagram:hover {
  color: #125688;
}

.ion-social-youtube:hover {
  color: #ff0000;
}

footer p {
  color: #888;
  text-align: center;
  margin-top: 20px;
}

footer .contact-gail {
  color: #888;
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
}

/* footer a:link,
footer a:visited {
  color: #e79913;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid #e79913;
  -webkit-transition: border-bottom 0.2s, color 0.2s;
  transition: border-bottom 0.2s, color 0.2s;
}

footer a:hover,
footer a:active {
  color: #ddd;
  border-bottom: 1px solid transparent;
} */

/* ----------------------------------------- */
/* Animations */
/* ----------------------------------------- */

.js--wp-1,
.js--wp-2,
.js--wp-3 {
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.js--wp-4 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.js--wp-1.animated,
.js--wp-2.animated,
.js--wp-3.animated {
  opacity: 1;
}

/* ----------------------------------------------- */
/* html5lightbox */
/* ----------------------------------------------- */
#html5-close {
  margin-top: 0 !important;
  margin-right: 0 !important;
}
.video-thumbnail {
  /* position: inherit;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer; */
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0;
}
.video-thumbnail:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: '\f01d';
  font-family: FontAwesome;
  font-size: 610%;
  color: #eee;
  opacity: 1;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.image-over:hover {
    /* opacity: 1; */
    filter: brightness(130%);
    -webkit-filter: brightness(130%);
}
.match-video .video-thumbnail:before {
  top: 58%;
  left: 55%;
}
.match-video .video-thumbnail:before {
  top: 58%;
  left: 55%;
}
.video-thumbnail:hover:before {
  color: #fff;
  opacity: 1;
}

.lightboxcontainer {
  display: block;
  /* position: fixed; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
  text-align: left;
  /* width: 900px;
    height: 675px; */
  max-height: 675px;
  overflow: auto;
}
/* .lightboxcontainer {
    display: block;
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 900px;
    height: 675px;
  } */
.lightboxleft {
  width: 50%;
  float: left;
  color: #ffffff;
  padding: 20px;
  line-height: 27px;
}
.lightboxright {
  width: 50%;
  float: right;
  color: #ffffff;
  padding: 20px;
  line-height: 27px;
}
.lightboxright iframe {
  min-height: 390px;
}
.divtext {
  margin: 36px;
}
@media (max-width: 800px) {
  .lightboxleft {
    width: 100%;
  }
  .lightboxright {
    width: 100%;
  }
  .divtext {
    margin: 12px;
  }
}

/*  end lightbox */

/* ----------------------------------------------- */
/* Match specific page */
/* ----------------------------------------------- */
.stats-box {
  width: 75%;
  margin: 0 auto;
  line-height: normal;
  max-width: 330px;
}

.stats-box-large {
  width: 85%;
  margin: 0 auto;
  line-height: normal;
  max-width: 920px;
}

.sup {
  font-size: 60%;
  top: -9px;
}

.img-60 {
  width: 60%;
}

.img-50 {
  width: 50%;
}
  /* end Match specific */

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-ring-centered {
  background-size: fixed;
  background-color: rgba(0, 0, 0, 1);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  z-index: 1000;
}
.loading-ring-centered {
  position: fixed;
}

#giving-tuesday-footer .copyright {
  display: flex;
  flex-direction: inherit;
  justify-content: center;
  margin-top: 10px;
  color: #8c8c8c;
}

#giving-tuesday-footer .logo {
  display: flex;
  flex-direction: inherit;
  justify-content: center;
  position: relative;
  top: 20px;
  z-index: 20;
 }

#giving-tuesday-footer .logoImage  {
     height: 65px
}

#giving-tuesday-footer .logoLink {
    text-decoration: none !important;
    border-bottom: 1px solid transparent !important;
}
