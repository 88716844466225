body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 320px) {
  html,
  body {
    overflow-x: hidden !important;
  }
}

@media (max-width: 480px) and (min-width: 321px) {
  html,
  body {
    overflow-x: hidden !important;
  }
}

@media (max-width: 575px) and (min-width: 481px) {
  html,
  body {
    overflow-x: hidden !important;
  }
}

.site-root {
  text-align: center;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.site-root-small {
  max-width: 1440px !important;
 }

.site-root {
  border-left: 1px solid #fff4eb;
  border-right: 1px solid #fff4eb;
}
.site-root {
  margin-left: auto;
  margin-right: auto;
}
.site-root, .fw-side-boxed .site-root {
  position: relative;
}
.site-root, .fw-side-boxed .site-root {
  margin-top: 0;
  margin-bottom: 0;
}

